import * as React from "react"
import SubblockRichText from "../../common/subblock/subblock-rich-text";
import SubblockHeadlineWithSubtitle from "../../common/subblock/subblock-headline-with-subtilte";
import SubblockList from "../../common/subblock/subblock-list";
import SubblockPictureLine from "../../common/subblock/subblock-picture-line";

const BlockTemplateTwoLists = ({content}) => {
  return (
  <>
    <SubblockHeadlineWithSubtitle title={content.title} subTitle={content.subTitle} noBottom />
    <SubblockRichText content={content.textLeft} position={"left"} />
    <SubblockRichText content={content.textRight} position={"right"} />
    <SubblockPictureLine content={content.pictures} showGallery />
    <SubblockList listTitle={content.listLeftTitle} list={content.listLeft} />
    <SubblockList listTitle={content.listRightTitle} list={content.listRight} />
  </>
)}

export default BlockTemplateTwoLists