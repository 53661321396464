import * as React from "react"
import SubblockRichText from "../../common/subblock/subblock-rich-text";
import SubblockHeadlineWithSubtitle from "../../common/subblock/subblock-headline-with-subtilte";
import SubblockPicture from "../../common/subblock/subblock-picture";

const BlockTemplateSmall = ({content}) => {
  return (
  <>
    <SubblockHeadlineWithSubtitle title={content.title} subTitle={content.subTitle} noBottom />
    <SubblockRichText content={content.text} position={"left"} />
    <SubblockPicture content={content.picture} />
  </>
)}

export default BlockTemplateSmall