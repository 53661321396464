import React from "react"
import { graphql } from "gatsby"
import Seo from "../components/seo";
import Layout from "../components/common/layout/layout";
import SubblockPageHeader from "../components/common/subblock/subblock-page-header";

import BlocksRenderer from "../components/subpage/block/blocks-renderer";

const SubPage = ({ data }) => {
  const subpage = data.strapiSubpage
  const header = data.strapiSubpage.header
  const seoTitle = data.strapiSubpage.header.title
  const seoDescription = data.strapiSubpage.header.titleText

  // const seo = {
  //   metaTitle: article.title,
  //   metaDescription: article.description,
  //   shareImage: article.cover,
  // }

  return (
    <>
      <Seo title={seoTitle} description={seoDescription} />
      <Layout>
        <SubblockPageHeader content={header} />
        <BlocksRenderer blocks={subpage.block || []} />
      </Layout>
    </>
  )
}

export const pageQuery = graphql`
  query ($slug: String) {
    strapiSubpage(slug: { eq: $slug }) {
      id
      slug
      linkTitle
      header {
        ...fragmentHeader
      }
      block {
        ...fragmentBlocks
      }
    }
  }
`

export default SubPage
