import React from 'react';


import SubblockWrapper from "./subblock-wrapper";
import ArrowList from "../ArrowList";
import Link from "../link";
import useSubpageSlugs from "../../../hooks/useSubpageSlugs";

const SubblockNavigationList =({content, fullwidth}) => {

  // get subpage slug
  const {categoryInkontinenzLinks, categoryErektionstörungenLinks} = useSubpageSlugs()

  // select Slug list
  const slugList = (content.linkListCategory === "inkontinenz") ? categoryInkontinenzLinks : categoryErektionstörungenLinks;

  // convert slugs to links list
  let listItems = []
  for (const slug of  slugList) {
    listItems.push({ title: <Link to={slug} passivClassName={"text-white"} /> });
  }

  // remove fist element, because is this subpage (example: "über Inkontinenz")
  listItems.shift();

  return (
    <>
      <SubblockWrapper fullwidth={fullwidth} colorTheme={"dark"} position="center" >
        <ArrowList header={content.linkListTitle} items={listItems || []} download />
      </SubblockWrapper>
    </>
  );
}

export default SubblockNavigationList;