import * as React from "react"
import SubblockRichText from "../../common/subblock/subblock-rich-text";
import SubblockList from "../../common/subblock/subblock-list";
import SubblockPictureLine from "../../common/subblock/subblock-picture-line";
import SubblockHeadlineWithSubtitle from "../../common/subblock/subblock-headline-with-subtilte";

const BlockTemplate1 = ({content}) => {
  return (
  <>
    <SubblockHeadlineWithSubtitle title={content.title} title2={content.title2} subTitle={content.subTitle} noBottom/>
    <SubblockRichText content={content.textLeft} position={"left"} />
    <SubblockRichText content={content.textRight} position={"right"} />
    <SubblockPictureLine content={content.pictures} showGallery />
    <SubblockList listTitle={content.listTitle} list={content.list} fullwidth/>
  </>
)}

export default BlockTemplate1