import React from "react"

import BlockTemplate1 from "./block-template-1"
import SectionLayout from "../../common/layout/sectionLayout";
import BlockTemplateSmall from "./block-template-small";
import BlockTemplateTwoLists from "./block-template-two-lists";
import BlockTemplateOverview from "./block-template-overview";


const componentsMap = {
  STRAPI__COMPONENT_TEMPLATES_SUBPAGE_TEMPLATES: BlockTemplate1,
  STRAPI__COMPONENT_TEMPLATES_TEMPLATE_SMALL: BlockTemplateSmall,
  STRAPI__COMPONENT_TEMPLATES_TEMPLATE_TWO_LISTS: BlockTemplateTwoLists,
  STRAPI__COMPONENT_TEMPLATES_TEMPLATE_OVERVIEW: BlockTemplateOverview,
}

const Block = ({ block }) => {

  const Component = componentsMap[block.__typename]

  if (!Component) {
    return null
  }

  return <Component content={block} />
}

const BlocksRenderer = ({ blocks }) => {
  return (
    <SectionLayout>
      {blocks.map((block, index) => (
        <Block key={`${index}${block.__typename}`} block={block} />
      ))}
    </SectionLayout>
  )
}

export default BlocksRenderer
