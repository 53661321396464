import * as React from "react"
import SubblockRichText from "../../common/subblock/subblock-rich-text";
import SubblockPicture from "../../common/subblock/subblock-picture";
import SubblockNavigationList from "../../common/subblock/subblock-navigation-list";
import ProductCarusell from "../../common/productCarusell/productCarusell";

const BlockTemplate1 = ({content}) => {
  return (
  <>
    <SubblockRichText content={content.textLeft} position={"left"} />
    <SubblockRichText content={content.textRight} position={"right"} />
    <SubblockPicture content={content.picture} />
    <SubblockNavigationList content={content} />
    <ProductCarusell header="BELIEBTE PRODUKTE" productCarouselCollectionTitle={content.productCarouselCollectionTitle} />
  </>
)}

export default BlockTemplate1